
<template>
    <div>
        <div class="particles-js-box">
            <div id="particles-js">
            </div>
        </div>
    </div>





</template>

<script>
    /* eslint-disable */
    import particlesJs from "particles.js";
    import particlesConfig from "./particles.json";
    export default {
        data() {
            return {
                username: "",
                password: "",
                isCheck: true
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                particlesJS("particles-js", particlesConfig);
                document.body.style.overflow = "hidden";
            }
        }
  } ;
</script>

<style scoped>
    .particles-js-box{
        position:fixed;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        z-index:1;  /*这里建议层级设为-1，但-1可能会导致被其他元素遮挡*/
    }
    #particles-js{
        background-color:#074a9d;
        width: 100%;
        height: 100%;
    }

    .login_black_hg {
        background: #fff;
        /* width: 400px; */
        border-radius: 6px;
        padding: 0px 40px 20px 40px;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari */
        padding-top: 40px;
        float: left;
        position: absolute;
        right: 30px;
        top: 180px;
    }

    .login_hg_left {
        float: left;
        position: absolute;
        left: -180px;
        top: -390px;

    }

    .login_input {
        margin: 20px 0 20px 0;
    }

    .login_btn {
        margin-top: 20px;
        width: 100%;
        font-size: 14px;
    }

    .must_rad {
        font-size: 12px;
    }

</style>
